
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFieldsContainerSocialMedia from '@/components/shared/tmFieldsContainer/TmFieldsContainerSocialMedia.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import {
  contactPhoneType,
  contactEmailType,
  assigneeOptions,
  organizationTypesOptions,
  organizationsFieldsList,
  industryOptions,
  organizationSizeOptions, tagsOptions
} from '@/definitions/_general/_data/optionsList'
import { utcZonesShort } from '@/definitions/_general/calendar/utc'
import TmFieldsContainerDomain from '@/components/shared/tmFieldsContainer/TmFieldsContainerDomain.vue'
import TmFieldsContainerPhone from '@/components/shared/tmFieldsContainer/TmFieldsContainerPhone.vue'
import TmFieldsContainerEmail from '@/components/shared/tmFieldsContainer/TmFieldsContainerEmail.vue'

export default defineComponent({
  components: {
    TmFieldsContainerEmail,
    TmFieldsContainerPhone,
    TmFieldsContainerDomain,
    TmModal,
    TmButton,
    TmFormLine,
    TmFieldsContainerSocialMedia,
  },
  props: {
    errorState: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: 'Add organization',
    },
    createAnother: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: 'Add',
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const additionalFieldsEmail = ref([
      { email: 'j.crowford@mail.com', type: 'Work' },
    ])
    const additionalFieldsPhone = ref([
      { phone: '(990) 338-9983', type: 'Work' },
    ])

    const addFieldAutofocus = ref('')

    const nameVal = ref(props.row.organization ? props.row.organization.organizationName : '')
    const domainsValue = ref((props.row.domains && props.row.domains.length) ? props.row.domains : [''])
    const typeValue = ref(props.row.type ? props.row.type : organizationTypesOptions[0])
    const country = ref(phoneCountryVariant[0])
    const assigneeListDefault = ref(assigneeOptions[2])
    const contactListDefault = ref(assigneeOptions[2])
    const socialMedia = ref(['https://facebook.com/TextMagic', 'https://twitter.com/TextMagic', 'https://linkedin.com/TextMagic'])
    const showCustomFields = ref(false)

    const tagsValue = ref(tagsOptions.slice(0, 2))
    const timezone = ref(utcZonesShort[113].value)

    const size = ref(organizationSizeOptions[0])
    const industry = ref(industryOptions[0])

    return {
      nameVal,
      size,
      industry,
      industryOptions,
      organizationSizeOptions,
      timezone,
      tagsValue,
      tagsOptions,
      contactPhoneType,
      contactEmailType,
      country,
      addFieldAutofocus,
      socialMedia,
      additionalFieldsPhone,
      additionalFieldsEmail,
      assigneeOptions,
      assigneeListDefault,
      domainsValue,
      typeValue,
      organizationTypesOptions,
      contactListDefault,
      organizationsFieldsList,
      showCustomFields,
    }
  },
})
